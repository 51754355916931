import { IRestUser } from '@/types'
import axios from 'axios'

class UsersClient {
  public me (token: string): Promise<IRestUser> {
    return axios.get('/api/users/me', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((res) => res.data)
  }
}

export default new UsersClient()
