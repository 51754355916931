import { Mixins, Component } from 'vue-property-decorator'
import cms from '@/cms'
import { Action, Getter, Mutation } from 'vuex-class'
import { ThemeHandler } from './theme-handler'
import { ISectionFormWithoutThemeAndSectionData } from '@/types'

@Component
export class AppHandler extends Mixins(ThemeHandler) {
  @Mutation('SET_CAMPAIGN_ID') setCampaignId!: (id: number) => void
  @Mutation('SET_FORM_COMPETITION') setForm!: (forrm: ISectionFormWithoutThemeAndSectionData) => void

  @Action('LOAD_CAMPAIGN') loadCampaign!: () => Promise<void>

  async $appInit ({ editor = true } = {}) {
    await cms.init({ i18n: true, iframeEnabled: editor, tracking: false })

    if (cms.campaign.appId) {
      this.setCampaignId(parseInt(cms.campaign.appId))
    }

    await this.loadCampaign()

    this.$themeInsertFont()
  }
}
